import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: "Liste des tresoreries",

            vuetable: {
                moreParams: {},
                fields: [
                    // {
                    //     name: VuetableFieldCheckbox,
                    //     titleClass: 'text-center',
                    //     dataClass: 'text-center'
                    // },
                    {
                        name: 'nom_tresorerie',
                        title: 'Nom du tresorerie',
                        sortField: 'nom_tresorerie',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'type_tresorerie',
                        title: 'Type du tresorerie',
                        sortField: 'type_tresorerie',
                        dataClass: 'text-center',
                        titleClass: 'text-center',
                        width: "150px"
                    },
                    {
                        name: 'solde',
                        title: 'Solde',
                        sortField: 'solde',
                        dataClass: 'text-right text-bold',
                        titleClass: 'text-center',
                        widht: '150px'
                    },
                    {
                        name: 'nom_activite',
                        title: 'Activité',
                        sortField: 'nom_activite',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'nom',
                        title: 'Utilisateurs',
                        sortField: 'nom',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'actions',
                        title: '-',
                        titleClass: 'text-center',
                        width: "40px",
                        dataClass: "text-center"
                    }

                ],
                sortOrder: [
                    { field: 'nom_tresorerie', direction: 'asc' }
                ],
                css: {
                    table: {
                        // tableClass: 'table table-striped table-bordered table-hovered',
                        tableClass: 'table-responsive w-100 d-block d-md-table table table-bordered table-hover',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Nom de la Tresorerie': 'nom_tresorerie',
                    'Type de la Tresorerie': 'type_tresorerie',
                    'Solde': 'solde',
                    'Nom du compte': 'nom_compte',
                    'Numero du compte': 'numero_compte',
                    'BIC': 'bic',
                    'IBAN': 'iban',
                    'BANQUE Correspondante': 'banque_correspondante',
                    'Code swift': 'code_swift',
                    'Utilisateur associé': 'nom'

                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            motCle: "",
            crudform: {
                id: "",
                nom_tresorerie: "",
                type_tresorerie: "CAISSE",
                caractere_tresorerie: "",
                solde: "",
                nom_compte: "",
                numero_compte: "",
                bic: "",
                iban: "",
                banque_correspondante: "",
                code_swift: "",
                users_id: "",

            },
            crudmodaltitle: "Ajouter une Tresorerie",
            listdata: {
                users: [],
            },
            activiteList: []

        }
    },
    methods: {
        onRowClass(dataItem, index) {
            if (index == "") console.log("");
            
            if(Number(dataItem.solde) < 0) {
                return 'text-danger text-bold';
            }
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        getActiviteList() {
            //alert("You clicked edit on" + JSON.stringify(rowData))
            axios.get(this.BASE_URL + "/activites/getall/").then(response => {
                this.activiteList = response.data;
                this.crudform.activites_id = this.$parent.users_activeplus_activites_id;
            });
        },
        editRow(rowData) {
            console.log(this.$parent.users_activeplus_activites_id);
            //alert("You clicked edit on" + JSON.stringify(rowData))
            axios.get(this.BASE_URL + "/tresoreries/get/" + rowData.id).then(response => {
                this.crudform = response.data[0];
                this.crudform.activites_id = this.crudform.activites_id == "0" || this.crudform.activites_id == "" || this.crudform.activites_id == null ? this.$parent.users_activeplus_activites_id : this.crudform.activites_id;
            });
            this.crudmodaltitle = "Editer une tresorerie";
            this.openModal();
        },
        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Supprimer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        axios.post(that.BASE_URL + "/tresoreries/delete/" + rowData.id).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                that.setFilter();
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.filter = this.motCle;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.fetchData();
            this.$bvModal.show("crudmodal");
        },
        closeModal() {
            this.$bvModal.hide("crudmodal");
        },
        addRow() {
            this.crudmodaltitle = "Ajouter une Tresorerie";
            this.crudform = {
                id: "",
                nom_tresorerie: "",
                type_tresorerie: "CAISSE",
                caractere_tresorerie: "",
                solde: "",
                nom_compte: "",
                numero_compte: "",
                bic: "",
                iban: "",
                banque_correspondante: "",
                code_swift: "",
                users_id: "",
                activites_id: this.$parent.users_activeplus_activites_id,

            };
            // console.log(this.crudform);
            this.openModal();
        },
        onSubmit() {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Continuer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = that.crudform.id == '' ? that.BASE_URL + "/tresoreries/addaction" : that.BASE_URL + "/tresoreries/editaction";
                        axios.post(link, $("#formulaire").serialize()).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                that.closeModal();
                                that.setFilter();
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        fetchData: function() {
            axios.get(this.BASE_URL + "/tresoreries/fetchdata").then(response => {
                console.log(response)
                this.listdata = response.data;
            });
        }
    },

    created: function() {
        this.$emit('change-page', this.pageTitle);

        this.motCle = "";
        this.criteriacol = "";

        this.getActiviteList();

    },
    mounted: function() {

    }
}
